<template>
  <div class="h-box-shadow">
    <el-table
      v-loading="table.loading"
      :data="table.data"
      max-height="500px"
      height="400px"
      size="medium"
      stripe
      tooltip-effect="light">
      <el-table-column
        prop="Sample_ID"
        min-width="180"
        label="Sample ID"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="Sample_Name"
        min-width="180"
        label="Sample Name"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="Sample_fractions"
        min-width="180"
        label="Sample fractions"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="SiO2"
        min-width="180"
        label="SiO2"
        show-overflow-tooltip>
        <template slot="header">SiO<sub>2</sub></template>
      </el-table-column>
      <el-table-column
        prop="TiO2"
        min-width="180"
        label="TiO2"
        show-overflow-tooltip>
        <template slot="header">TiO<sub>2</sub></template>
      </el-table-column>
      <el-table-column
        prop="Al2O3"
        min-width="180"
        label="Al2O3"
        show-overflow-tooltip>
        <template slot="header">Al<sub>2</sub>O<sub>3</sub></template>
      </el-table-column>
      <el-table-column
        prop="Fe2O3"
        min-width="180"
        label="Fe2O3"
        show-overflow-tooltip>
        <template slot="header">Fe<sub>2</sub>O<sub>3</sub></template>
      </el-table-column>
      <el-table-column
        prop="Fe2O3T"
        min-width="180"
        label="Fe2O3T"
        show-overflow-tooltip>
        <template slot="header">Fe<sub>2</sub>O<sub>3</sub>T</template>
      </el-table-column>
      <el-table-column
        prop="FeO"
        min-width="180"
        label="FeO"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="FeOT"
        min-width="180"
        label="FeOT"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="MnO"
        min-width="180"
        label="MnO"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="MgO"
        min-width="180"
        label="MgO"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="CaO"
        min-width="180"
        label="CaO"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="Na2O"
        min-width="180"
        label="Na2O"
        show-overflow-tooltip>
        <template slot="header">Na<sub>2</sub>O</template>
      </el-table-column>
      <el-table-column
        prop="K2O"
        min-width="180"
        label="K2O"
        show-overflow-tooltip>
        <template slot="header">K<sub>2</sub>O</template>
      </el-table-column>
      <el-table-column
        prop="P2O5"
        min-width="180"
        label="P2O5"
        show-overflow-tooltip>
        <template slot="header">P<sub>2</sub>O<sub>5</sub></template>
      </el-table-column>
      <el-table-column
        prop="LOI_loss_on_ignition"
        min-width="180"
        label="LOI (loss on ignition)"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="H2O"
        min-width="180"
        label="H2O"
        show-overflow-tooltip>
        <template slot="header">H<sub>2</sub>O</template>
      </el-table-column>
      <el-table-column
        prop="H2O_bound_water"
        min-width="180"
        label="H2O+ (bound water)"
        show-overflow-tooltip>
        <template slot="header">H<sub>2</sub>O+ (bound water)</template>
      </el-table-column>
      <el-table-column
        prop="H2O_moisture_water"
        min-width="180"
        label="H2O- (moisture water)"
        show-overflow-tooltip>
        <template slot="header">H<sub>2</sub>O- (moisture water)</template>
      </el-table-column>
      <el-table-column
        prop="CO2"
        min-width="180"
        label="CO2"
        show-overflow-tooltip>
        <template slot="header">CO<sub>2</sub></template>
      </el-table-column>
      <el-table-column
        prop="SO2"
        min-width="180"
        label="SO2"
        show-overflow-tooltip>
        <template slot="header">SO<sub>2</sub></template>
      </el-table-column>
      <el-table-column
        prop="F"
        min-width="180"
        label="F"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="Cl"
        min-width="180"
        label="Cl"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="Total"
        min-width="180"
        label="Total"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="Cr2O3"
        min-width="180"
        label="Cr2O3"
        show-overflow-tooltip>
        <template slot="header">Cr<sub>2</sub>O<sub>3</sub></template>
      </el-table-column>
      <el-table-column
        prop="NiO"
        min-width="180"
        label="NiO"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="La2O3"
        min-width="180"
        label="La2O3"
        show-overflow-tooltip>
        <template slot="header">La<sub>2</sub>O<sub>3</sub></template>
      </el-table-column>
      <el-table-column
        prop="Ce2O3"
        min-width="180"
        label="Ce2O3"
        show-overflow-tooltip>
        <template slot="header">Ce<sub>2</sub>O<sub>3</sub></template>
      </el-table-column>
      <el-table-column
        prop="Pr2O3"
        min-width="180"
        label="Pr2O3"
        show-overflow-tooltip>
        <template slot="header">Pr<sub>2</sub>O<sub>3</sub></template>
      </el-table-column>
      <el-table-column
        prop="Nd2O3"
        min-width="180"
        label="Nd2O3"
        show-overflow-tooltip>
        <template slot="header">Nd<sub>2</sub>O<sub>3</sub></template>
      </el-table-column>
      <el-table-column
        prop="Sm2O3"
        min-width="180"
        label="Sm2O3"
        show-overflow-tooltip>
        <template slot="header">Sm<sub>2</sub>O<sub>3</sub></template>
      </el-table-column>
      <el-table-column
        prop="Eu2O3"
        min-width="180"
        label="Eu2O3"
        show-overflow-tooltip>
        <template slot="header">Eu<sub>2</sub>O<sub>3</sub></template>
      </el-table-column>
      <el-table-column
        prop="Gd2O3"
        min-width="180"
        label="Gd2O3"
        show-overflow-tooltip>
        <template slot="header">Gd<sub>2</sub>O<sub>3</sub></template>
      </el-table-column>
      <el-table-column
        prop="Tb2O3"
        min-width="180"
        label="Tb2O3"
        show-overflow-tooltip>
        <template slot="header">Tb<sub>2</sub>O<sub>3</sub></template>
      </el-table-column>
      <el-table-column
        prop="Dy2O3"
        min-width="180"
        label="Dy2O3"
        show-overflow-tooltip>
        <template slot="header">Dy<sub>2</sub>O<sub>3</sub></template>
      </el-table-column>
      <el-table-column
        prop="Ho2O3"
        min-width="180"
        label="Ho2O3"
        show-overflow-tooltip>
        <template slot="header">Ho<sub>2</sub>O<sub>3</sub></template>
      </el-table-column>
      <el-table-column
        prop="Er2O3"
        min-width="180"
        label="Er2O3"
        show-overflow-tooltip>
        <template slot="header">Er<sub>2</sub>O<sub>3</sub></template>
      </el-table-column>
      <el-table-column
        prop="Tm2O3"
        min-width="180"
        label="Tm2O3"
        show-overflow-tooltip>
        <template slot="header">Tm<sub>2</sub>O<sub>3</sub></template>
      </el-table-column>
      <el-table-column
        prop="Yb2O3"
        min-width="180"
        label="Yb2O3"
        show-overflow-tooltip>
        <template slot="header">Yb<sub>2</sub>O<sub>3</sub></template>
      </el-table-column>
      <el-table-column
        prop="Lu2O3"
        min-width="180"
        label="Lu2O3"
        show-overflow-tooltip>
        <template slot="header">Lu<sub>2</sub>O<sub>3</sub></template>
      </el-table-column>
      <el-table-column
        prop="Y2O3"
        min-width="180"
        label="Y2O3"
        show-overflow-tooltip>
        <template slot="header">Y<sub>2</sub>O<sub>3</sub></template>
      </el-table-column>
      <el-table-column
        prop="UO2"
        min-width="180"
        label="UO2"
        show-overflow-tooltip>
        <template slot="header">UO<sub>2</sub></template>
      </el-table-column>
      <el-table-column
        prop="ThO2"
        min-width="180"
        label="ThO2"
        show-overflow-tooltip>
        <template slot="header">ThO<sub>2</sub></template>
      </el-table-column>
      <el-table-column
        prop="PbO"
        min-width="180"
        label="PbO"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="Li2O"
        min-width="180"
        label="Li2O"
        show-overflow-tooltip>
        <template slot="header">Li<sub>2</sub>O</template>
      </el-table-column>
      <el-table-column
        prop="Rb2O"
        min-width="180"
        label="Rb2O"
        show-overflow-tooltip>
        <template slot="header">Rb<sub>2</sub>O</template>
      </el-table-column>
      <el-table-column
        prop="Cs2O"
        min-width="180"
        label="Cs2O"
        show-overflow-tooltip>
        <template slot="header">Cs<sub>2</sub>O</template>
      </el-table-column>
      <el-table-column
        prop="BeO"
        min-width="180"
        label="BeO"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="SrO"
        min-width="180"
        label="SrO"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="BaO"
        min-width="180"
        label="BaO"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="B2O3"
        min-width="180"
        label="B2O3"
        show-overflow-tooltip>
        <template slot="header">B<sub>2</sub>O<sub>3</sub></template>
      </el-table-column>
      <el-table-column
        prop="In2O3"
        min-width="180"
        label="In2O3"
        show-overflow-tooltip>
        <template slot="header">In<sub>2</sub>O<sub>3</sub></template>
      </el-table-column>
      <el-table-column
        prop="SnO2"
        min-width="180"
        label="SnO2"
        show-overflow-tooltip>
        <template slot="header">SnO<sub>2</sub></template>
      </el-table-column>
      <el-table-column
        prop="As2O3"
        min-width="180"
        label="As2O3"
        show-overflow-tooltip>
        <template slot="header">As<sub>2</sub>O<sub>3</sub></template>
      </el-table-column>
      <el-table-column
        prop="Sb2O3"
        min-width="180"
        label="Sb2O3"
        show-overflow-tooltip>
        <template slot="header">Sb<sub>2</sub>O<sub>3</sub></template>
      </el-table-column>
      <el-table-column
        prop="Bi2O3"
        min-width="180"
        label="Bi2O3"
        show-overflow-tooltip>
        <template slot="header">Bi<sub>2</sub>O<sub>3</sub></template>
      </el-table-column>
      <el-table-column
        prop="Sc2O3"
        min-width="180"
        label="Sc2O3"
        show-overflow-tooltip>
        <template slot="header">Sc<sub>2</sub>O<sub>3</sub></template>
      </el-table-column>
      <el-table-column
        prop="ZrO2"
        min-width="180"
        label="ZrO2"
        show-overflow-tooltip>
        <template slot="header">ZrO<sub>2</sub></template>
      </el-table-column>
      <el-table-column
        prop="HfO2"
        min-width="180"
        label="HfO2"
        show-overflow-tooltip>
        <template slot="header">HfO<sub>2</sub></template>
      </el-table-column>
      <el-table-column
        prop="V2O3"
        min-width="180"
        label="V2O3"
        show-overflow-tooltip>
        <template slot="header">V<sub>2</sub>O<sub>3</sub></template>
      </el-table-column>
      <el-table-column
        prop="V2O5"
        min-width="180"
        label="V2O5"
        show-overflow-tooltip>
        <template slot="header">V<sub>2</sub>O<sub>5</sub></template>
      </el-table-column>
      <el-table-column
        prop="Nb2O5"
        min-width="180"
        label="Nb2O5"
        show-overflow-tooltip>
        <template slot="header">Nb<sub>2</sub>O<sub>5</sub></template>
      </el-table-column>
      <el-table-column
        prop="Ta2O5"
        min-width="180"
        label="Ta2O5"
        show-overflow-tooltip>
        <template slot="header">Ta<sub>2</sub>O<sub>5</sub></template>
      </el-table-column>
      <el-table-column
        prop="MoO3"
        min-width="180"
        label="MoO3"
        show-overflow-tooltip>
        <template slot="header">MoO<sub>3</sub></template>
      </el-table-column>
      <el-table-column
        prop="WO3"
        min-width="180"
        label="WO3"
        show-overflow-tooltip>
        <template slot="header">WO<sub>3</sub></template>
      </el-table-column>
      <el-table-column
        prop="CuO"
        min-width="180"
        label="CuO"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="Ag2O3"
        min-width="180"
        label="Ag2O3"
        show-overflow-tooltip>
        <template slot="header">Ag<sub>2</sub>O<sub>3</sub></template>
      </el-table-column>
      <el-table-column
        prop="ZnO"
        min-width="180"
        label="ZnO"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="CdO"
        min-width="180"
        label="CdO"
        show-overflow-tooltip>
      </el-table-column>
    </el-table>
    <!-- <el-pagination
      class="pager"
      background
      layout="prev, pager, next"
      :total="page.total">
    </el-pagination> -->
  </div>
</template>

<script>
import dPrincipalElementApi from '@/api/dPrincipalElement'
export default {
  name: 'Search_Detail_dPrincipalElement',
  data() {
    return {
      sampleId: this.$route.query.sampleId,
      table: {
        loading: false,
        data: []
      },
      page: {
        index: 1,
        size: 100,
        total: 0
      }
    }
  },
  methods: {
    getData() {
      if (!this.sampleId) {return}
      this.table.loading = true
      dPrincipalElementApi.getPageData({
        page: this.page.index,
        rows: this.page.size,
        wheres: `[
          {"name":"Sample_ID","value":"${this.sampleId}","displayType":"number"}
        ]`.replace(/\s+/g,"")
      }).then(res => {
        this.table.loading = false
        this.table.data = res.rows
        this.page.total = res.total
      }).catch(err => {
        this.talbe.loading = false
      })
    }
  },
  mounted() {
    this.getData()
  }
}
</script>

<style lang="less" scoped>
  /deep/ .el-pagination {
    margin: 12px 0;
  }
</style>
